.container {
  --padding: rem(2);

  contain: strict;
  background-color: clr(lightable-b);
  color: clr(cw);
  border-radius: 100%;
  line-height: 1;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(1);
}

.icon {
  transform: rotate(180deg);
  position: absolute;
  left: var(--padding);
  top: var(--padding);
  width: calc(100% - (var(--padding) * 2));
  height: calc(100% - (var(--paddding) * 2));
}

.pathBg,
.pathFg {
  stroke-width: var(--padding);
  fill: none;
}

.pathFg {
  stroke-linecap: round;
}

.variantViolet {
  --grad-start: clr(b-20);
  --grad-stop: clr(b-25);

  .pathBg {
    stroke: clr(b-25/20);
  }

  .pathFg {
    stroke: var(--gradid);
  }
}

.variantBase {
  --grad-start: clr(#0ff);
  --grad-stop: clr(p-70);

  .pathBg {
    stroke: clr(p-70/20);
  }

  .pathFg {
    stroke: var(--gradid);
  }
}
